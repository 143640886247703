import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../../assets/css/reports.css";
import placeholderImg from "../../../../../assets/media/user.png";
import blockIcon from "../../../../../assets/media/block.png";
import cancelIcon from "../../../../../assets/media/clear.png";
import { apiUrl, imagesUrl } from "../../../../../config";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";
import DynamicTable from "../../../../tables/DynamicTable";
import InputContainer from "../../../../utils/inputContainer";
import arrow from "../../../../../assets/media/arrow.png";
import AreYouSure from "../../../../popup/areYouSure";
import exclamationMarkIcon from "../../../../../assets/media/danger.png";
import CallAPI from "../../../../api/callApi";
import ReportOperationsCard from "../../../../cards/ReportOperationsCard";
const PostReportsDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("reports");
  const [textareaVisible, setTextAreaVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState([]);
  const [questionText, setQuestionText] = useState("");
  const [actionType, setActionType] = useState();
  const [report, setReport] = useState(location.state?.report);
  const [cameFromProfile, setCameFromProfile] = useState(false);
  const locationArray = [
    [t("reports"), "/brand/reports"],
    [t("reportsListDetail"), "/brand/reports/detail"],
  ];
  const [brandBan, setBrandBan] = useState();
  const [challengeBan, setChallengeBan] = useState();
  const [campaignBan, setCampaignBan] = useState();
  const [chosenReportId, setChosenReportId] = useState();
  const { id } = useParams(); // URL'den id'yi al

  const formatDateStringLocal = (dateString) => {
    const utcDate = new Date(dateString);
    utcDate.setHours(utcDate.getHours() + 3);
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");
    return `${formattedDate} ${formattedTime}`;
  };

  const fetchReportsOfTheUser = async (userid) => {
    try {
      const responseTest = await CallAPI({
        method: "GET",
        endPoint: apiUrl + "/api/v1/brand-admin/brand/ban/sotier/" + userid,
        dontShowError: true,
        t: t,
      });
      console.log(responseTest);
      setChallengeBan(responseTest.data.responseData.challengeBan);
      setBrandBan(responseTest.data.responseData.brandBan);
      setCampaignBan(responseTest.data.responseData.campaignBan);
    } catch {
      console.log("500 döndü");
    }
  };

  const fetchUserReports = async (id) => {
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: apiUrl + "/api/v1/brand-admin/report/sotier/" + id,
        dontShowError: true,
        t: t,
      });

      const reports = response.data.responseData;
      console.log("REPORTS", reports);
      // Verileri `data` formatına dönüştür
      const formattedData = reports.map((report) => ({
        reported: (
          <div
            className="sotier-list-sotier-container"
            key={report.reported.userId}
            onClick={() => {
              navigate(`/brand/srm/sotier/profile/${report.reporter.userId}`);
            }}
          >
            <img
              className="sotier-list-sotier-image"
              src={
                report.reported.logo
                  ? `${imagesUrl}/${report.reported.logo}`
                  : placeholderImg
              }
              alt="logo"
            />
            <div className="sotier-list-sotier-details-container">
              <div className="sotier-list-sotier-details-full-name">
                {report.reported.firstName} {report.reported.lastName}
              </div>
              <div className="sotier-list-sotier-details-id">
                ID: {report.reported.userId}
              </div>
              <div className="sotier-list-sotier-details-report">
                {t("reportedReportCount")}: {report.reportCountToSamePost}
              </div>
            </div>
          </div>
        ),
        reporter: report.reporter && (
          <div
            className="sotier-list-sotier-container"
            key={report.reporter.userId}
            onClick={() => {
              navigate(`/brand/srm/sotier/profile/${report.reporter.userId}`);
            }}
          >
            <img
              className="sotier-list-sotier-image"
              src={
                report.reporter.logo
                  ? `${imagesUrl}/${report.reporter.logo}`
                  : placeholderImg
              }
              alt="logo"
            />
            <div className="sotier-list-sotier-details-container">
              <div className="sotier-list-sotier-details-full-name">
                {report.reporter.firstName} {report.reporter.lastName}
              </div>
              <div className="sotier-list-sotier-details-id">
                ID: {report.reporter.userId}
              </div>
              <div className="sotier-list-sotier-details-report">
                {t("reporterReportCount")}: {report.reportCountFromReporter}
              </div>
            </div>
          </div>
        ),
        provider: t(report.socialProvider),
        creationDate: formatDateStringLocal(report.created),
        reason: t(report.type),
        status: (
          <>
            <div className="table-unscrollable-button-container-row">
              {t(report.status)}
            </div>
            <br />
            <div className="table-unscrollable-button-container-row">
              <div
                className="investigate-button"
                onClick={() => setChosenReportId(report.id)}
              >
                {chosenReportId === report.id
                  ? t("takingAction")
                  : t("takeAction")}
              </div>
            </div>
          </>
        ),
      }));

      setData(formattedData);
      if (reports.length === 0) {
        fetchSotier(id);
      }
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };

  const fetchSotier = async (id) => {
    const response = await CallAPI({
      method: "get",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/" + id + "/profile",
    });
    const responseData = response.data.responseData;
    console.log(response.data.responseData);
    setReport({
      reported: {
        userId: responseData.sotierId,
        userName: responseData.userName,
        firstName: responseData.firstName,
        lastName: responseData.lastName,
        logo: responseData.logo,
        manual: true,
      },
    });
    const formattedData = {
      reported: (
        <div
          className="sotier-list-sotier-container"
          key={responseData.sotierId}
          onClick={() => {
            navigate(`/brand/srm/sotier/profile/${responseData.sotierId}`);
          }}
        >
          <img
            className="sotier-list-sotier-image"
            src={
              responseData.logo
                ? `${imagesUrl}/${responseData.logo}`
                : placeholderImg
            }
            alt="logo"
          />
          <div className="sotier-list-sotier-details-container">
            <div className="sotier-list-sotier-details-full-name">
              {responseData.firstName} {responseData.lastName}
            </div>
            <div className="sotier-list-sotier-details-id">
              ID: {responseData.sotierId}
            </div>
          </div>
        </div>
      ),
    };
    setData([formattedData]);
  };

  useEffect(() => {
    if (report) {
      fetchReportsOfTheUser(report.reported.userId);
      fetchUserReports(report.reported.userId);
    } else if (id) {
      console.log("report yok");
      fetchUserReports(id);
    }
  }, []);
  const columns = [
    { title: t("reported"), field: "reported", className: "sotier" },
    { title: t("reporter"), field: "reporter", className: "sotier" },
    { title: t("provider"), field: "provider" },
    { title: t("creationDate"), field: "creationDate" },
    { title: t("reason"), field: "reason" },
    { title: t("status"), field: "status" },
  ];

  const returnToSelection = () => {
    setTextAreaVisible(false);
  };

  const handleOperation = () => {
    setPopup(true);
  };

  const doSomethingToUser = async () => {
    let endPoint, successMessage, errorMessage, body;
    console.log(actionType);
    switch (actionType) {
      case "warn":
        endPoint = `${apiUrl}/api/v1/brand-admin/report/sotier/warn`;
        successMessage = t("userWarnedSuccessfully");
        errorMessage = t("errorWarningUser");
        body = {
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          reports: [chosenReportId],
          heading: t("warnUserHeading"),
        };
        break;
      case "brandBan":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userUnblockedFromBrandSuccessfully");
        errorMessage = t("errorUnblockingUserFromBrand");
        body = {
          brandBan: true,
          challengeBan: challengeBan,
          campaignBan: campaignBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("blockFromBrandHeading"),
        };
        break;
      case "challengeBan":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userBlockedFromChallengesSuccessfully");
        errorMessage = t("errorBlockingUserFromChallenges");
        body = {
          challengeBan: true,
          campaignBan: campaignBan,
          brandBan: brandBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("blockFromChallengesHeading"),
        };
        break;
      case "campaignBan":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userBlockedFromCampaignSuccessfully");
        errorMessage = t("errorBlockingUserFromCampaigns");
        body = {
          campaignBan: true,
          challengeBan: challengeBan,
          brandBan: brandBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("blockFromCampaignsHeading"),
        };
        break;
      case "doNothing":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("didNothingToUserSuccessfully");
        errorMessage = t("failedToTakeActionOnUser");
        body = {
          challengeBan: challengeBan,
          campaignBan: campaignBan,
          brandBan: brandBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
        };
        break;
      case t("removeChallengeBan"):
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userUnblockedFromChallengesSuccessfully");
        errorMessage = t("errorUnblockingUserFromChallenges");
        body = {
          challengeBan: false,
          campaignBan: campaignBan,
          brandBan: brandBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("unblockFromChallengesHeading"),
        };
        break;
      case "removeBrandBan":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userUnblockedFromBrandSuccessfully");
        errorMessage = t("errorUnblockingUserFromBrand");
        body = {
          brandBan: false,
          challengeBan: challengeBan,
          campaignBan: campaignBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("unblockFromBrandHeading"),
        };
        break;
      case "removeCampaignBan":
        endPoint = `${apiUrl}/api/v1/brand-admin/brand/ban/sotier`;
        successMessage = t("userUnblockedFromCampaignsSuccessfully");
        errorMessage = t("errorUnblockingUserFromCampaigns");
        body = {
          campaignBan: false,
          challengeBan: challengeBan,
          brandBan: brandBan,
          reports: [chosenReportId],
          sotierId: {
            id: report.reported.userId,
          },
          message: message, // Dinamik mesaj
          heading: t("unblockFromCampaignsHeading"),
        };
        break;
      default:
        console.error("Unknown action type");
        return;
    }

    console.log(body);
    try {
      const response = await CallAPI({
        method: "PUT",
        endPoint,
        body: body,
        successMessage,
        errorMessage,
        t: t,
      });
      console.log(response);

      if (response.status === 200) {
        navigate("/brand/reports");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleActionType = (actionMethod, remove) => {
    console.log(actionMethod);
    console.log(remove);

    if (remove !== undefined && remove !== false) {
      console.log("TRUE");

      if (actionMethod === "challengeBan") {
        setActionType(t("removeChallengeBan"));
        setQuestionText(t("unblockFromChallengesConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("unblockFromChallengesText"));
      } else if (actionMethod === "campaignBan") {
        setActionType(t("removeCampaignBan"));
        setQuestionText(t("unblockFromCampaignsConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("unblockFromCampaignsText"));
      } else if (actionMethod === "brandBan") {
        setActionType(t("removeBrandBan"));
        setQuestionText(t("unblockFromBrandConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("unblockFromBrandText"));
      } else {
        console.log("MAL");
      }
    } else {
      console.log("faalse");

      if (actionMethod === "warn") {
        setActionType(actionMethod);
        setQuestionText(t("warnUserConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("warnUserText"));
      } else if (actionMethod === "challengeBan") {
        setActionType(actionMethod);
        setQuestionText(t("blockFromChallengesConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("blockFromChallengesText"));
      } else if (actionMethod === "campaignBan") {
        setActionType(actionMethod);
        setQuestionText(t("blockFromCampaignsConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("blockFromCampaignsText"));
      } else if (actionMethod === "brandBan") {
        setActionType(actionMethod);
        setQuestionText(t("blockFromBrandConfirmation"));
        setTextAreaVisible(true);
        setMessage(t("blockFromBrandText"));
      } else if (actionMethod === "doNothing") {
        setActionType(actionMethod);
        setQuestionText(t("doNothingConfirmation"));
        setMessage("");
        setTextAreaVisible(false);
        setPopup(true);
      } else {
        console.log("MAL");
      }
    }
  };

  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />

      <DynamicTable columns={columns} data={data} unsortable={true} />
      {popup && (
        <AreYouSure
          questionText={questionText}
          onCancel={() => {
            setPopup(false);
          }}
          onAccept={() => {
            doSomethingToUser();
          }}
          isCondition={true}
          cancelText={t("cancel")}
          acceptText={t("yes")}
          content={
            <>
              <div
                className="sotier-list-sotier-container reported-sotier-popup-container"
                onClick={() => {
                  navigate(
                    `/brand/srm/sotier/profile/${report.reported.userId}`
                  );
                }}
              >
                <img
                  className="sotier-list-sotier-image"
                  src={
                    report.reported.logo
                      ? `${imagesUrl}/${report.reported.logo}`
                      : placeholderImg
                  }
                  alt="logo"
                />
                <div className="sotier-list-sotier-details-container">
                  <div className="sotier-list-sotier-details-full-name">
                    {report.reported.firstName} {report.reported.lastName}
                  </div>

                  <div className="sotier-list-sotier-details-id">
                    ID: {report.reported.userId}
                  </div>
                </div>
              </div>
              {message && (
                <div className="report-decision-message-container">
                  <div>{t("messageToSend")} :</div>
                  <div>{message}</div>
                </div>
              )}
            </>
          }
          isNegative={true}
        />
      )}

      {textareaVisible ? (
        <div className="challenge-creation-content-container">
          <InputContainer
            title={t("operation")}
            titleDescription={
              "Şikayet değerlendirme neticesinde halinde aşağıdaki seçeneklerden birini seçebilirsiniz."
            }
          >
            <>
              <div
                className="report-operation-return-button"
                onClick={() => {
                  returnToSelection();
                }}
              >
                <img src={arrow} alt="<" />
                {t("returnToChoice")}
              </div>
              <div className="report-operation-message-container">
                <div className="report-operation-message-top">
                  <div className="report-operation-message-top-left">
                    <div
                      className="sotier-list-sotier-container"
                      onClick={() => {
                        navigate(
                          `/brand/srm/sotier/profile/${report.reported.userId}`
                        );
                      }}
                    >
                      <img
                        className="sotier-list-sotier-image"
                        src={
                          report.reported.logo
                            ? `${imagesUrl}/${report.reported.logo}`
                            : placeholderImg
                        }
                        alt="logo"
                      />
                      <div className="sotier-list-sotier-details-container">
                        <div className="sotier-list-sotier-details-full-name">
                          {report.reported.firstName} {report.reported.lastName}
                        </div>
                        <div className="sotier-list-sotier-details-id">
                          ID: {report.reported.userId}
                        </div>
                        <div className="sotier-list-sotier-details-report">
                          {t("reportedReportCount")}:{" "}
                          {report.reportCountToSamePost}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="report-operation-message-top-right">
                    {formatDateStringLocal(report.created)}
                  </div>
                </div>
                <hr />
                <div className="report-operation-message-header">
                  <div className="report-operation-message-header-title">
                    {t("decision")}:
                  </div>
                  <div className="report-operation-message-header-data">
                    <div className="report-operations-card-decision-container">
                      {t(actionType)}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="report-operation-message-body">
                  <textarea
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                <hr />
                <div className="report-operation-message-footer">
                  <div
                    className="report-operations-card-button fit-content"
                    onClick={() => {
                      handleOperation();
                    }}
                  >
                    {t("send")}
                  </div>
                </div>
              </div>
            </>
          </InputContainer>
        </div>
      ) : (
        <div className="challenge-creation-content-container">
          {
            <InputContainer
              title={t("operation")}
              titleDescription={t("operationDescription")}
            >
              <div className="report-operations-wrapper">
                <ReportOperationsCard
                  Title={t("doNothing")}
                  Description={t("doNothingDescription")}
                  buttonText={t("doNothing")}
                  cardImg={cancelIcon}
                  positive={true}
                  onClick={() => {
                    handleActionType("doNothing", false);
                  }}
                />
                <ReportOperationsCard
                  Title={t("warn")}
                  Description={t("warnDescription")}
                  buttonText={t("warnUser")}
                  cardImg={exclamationMarkIcon}
                  warning={true}
                  onClick={() => {
                    handleActionType("warn", false);
                  }}
                />
                <ReportOperationsCard
                  Title={
                    challengeBan ? t("removeChallengeBan") : t("challengeBan")
                  }
                  Description={
                    challengeBan
                      ? t("removeChallengeBanDescription")
                      : t("challengeBanDescription")
                  }
                  buttonText={
                    challengeBan
                      ? t("removeChallengeBanUser")
                      : t("challengeBanUser")
                  }
                  cardImg={blockIcon}
                  positive={challengeBan}
                  onClick={() => {
                    handleActionType("challengeBan", challengeBan);
                  }}
                />
                <ReportOperationsCard
                  Title={
                    campaignBan ? t("removeCampaignBan") : t("campaignBan")
                  }
                  Description={
                    campaignBan
                      ? t("removeCampaignBanDescription")
                      : t("campaignBanDescription")
                  }
                  buttonText={
                    campaignBan
                      ? t("removeCampaignBanUser")
                      : t("campaignBanUser")
                  }
                  cardImg={blockIcon}
                  positive={campaignBan}
                  onClick={() => {
                    handleActionType("campaignBan", campaignBan);
                  }}
                />
                <ReportOperationsCard
                  Title={brandBan ? t("removeBrandBan") : t("brandBan")}
                  Description={
                    brandBan
                      ? t("removeBrandBanDescription")
                      : t("brandBanDescription")
                  }
                  buttonText={
                    brandBan ? t("removeBrandBanUser") : t("brandBanUser")
                  }
                  cardImg={blockIcon}
                  positive={brandBan}
                  onClick={() => {
                    handleActionType("brandBan", brandBan);
                  }}
                />
              </div>
            </InputContainer>
          }
        </div>
      )}
    </div>
  );
};

export default PostReportsDetail;
