import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../assets/media/upload.png";
import "../../assets/css/ChallengeImageDropzone.css"; // You can create a CSS file for styling
import { useTranslation } from "react-i18next";

const ChallengeImageDropzone = ({
  onImageUpload,
  currentImg,
  isSavedTrue,
  clearImage,
  defaultImageUrl,
  brandLogo,
}) => {
  const acceptedFileTypes = ["image/jpeg", "image/png"];
  const [uploadedImage, setUploadedImage] = useState(null);
  const noImageToShow =
    "https://images.dev.soty.io/d27815bc-88e6-46e5-b7d9-0d9d790e8f0d5868971559547298584";
  const defaultSotyLogo =
    "https://images.dev.soty.io/917f80c5-97e3-4fca-8cce-6247a5d1afa711043674371957123852";
  const defaultImageHost = "https://images.dev.soty.io/";
  const { t, i18n } = useTranslation("challengeCreation");

  const handleImageUpload = useCallback(
    (imageSrc) => {
      setUploadedImage(imageSrc);
      onImageUpload(imageSrc); // Call the callback with the uploaded image source
    },
    [onImageUpload]
  );
  useEffect(() => {
    setUploadedImage(null); // Clear the uploaded image when isSavedTrue is true
  }, [clearImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Check if each file is a valid image file (JPG or PNG)
      const validFiles = acceptedFiles.filter((file) =>
        acceptedFileTypes.includes(file.type)
      );

      if (validFiles.length > 0) {
        // Assuming you want to display the first valid image
        const file = validFiles[0];
        const imageSrc = URL.createObjectURL(file);
        handleImageUpload(imageSrc);
      } else {
        // Handle invalid file types (e.g., show an error message)
        console.error("Invalid file types. Please upload JPG or PNG images.");
      }
    },
    [handleImageUpload]
  );

  let imageToShow = undefined;

  if (uploadedImage != null && uploadedImage != undefined) {
    imageToShow = uploadedImage;
  } else if (currentImg != null && currentImg != undefined) {
    imageToShow = defaultImageHost + currentImg;
  } else {
    imageToShow = defaultImageUrl; // Use the default image URL provided as a prop
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(","), // Accept only JPG and PNG
  });

  // Define the class name based on whether an image is uploaded
  const dropzoneClassName = 
  uploadedImage 
    ? (brandLogo 
        ? "brand-logo-dropzone-uploaded" 
        : "challenge-image-dropzone-uploaded") 
    : (brandLogo 
        ? "challenge-image-dropzone" 
        : "challenge-image-dropzone");

  return (
    <div className={dropzoneClassName} {...getRootProps()}>
      {imageToShow ? ( // Check if there is an image to display
        <div className={
          brandLogo ? "brand-logo-dropzone" : "challenge-image-dropzone"
        }>
          <img
            className={
              brandLogo ? "brand-logo-uploaded" : "challenge-image-uploaded"
            }
            src={imageToShow}
            alt="challenge image"
          />
          <input {...getInputProps()} />
        </div>
      ) : (
        <div className="challenge-image-dropzone-wrapper">
          <input {...getInputProps()} />
          <div className="challenge-image-upload-icon-wrapper">
            <img
              className="challenge-image-upload-icon"
              src={upload}
              alt="uploadico"
            />
          </div>
          <p>{t("uploadImage")}</p>
        </div>
      )}
    </div>
  );
};

export default ChallengeImageDropzone;
