import React, { useState, useEffect } from "react";
import "../../../../../../assets/css/InteractionCreation.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../../../../popup/loader";
import SingleTextInput from "../../../../../utils/singleTextInput";
import { toast } from "react-toastify";
import SingleTextTextarea from "../../../../../utils/singleTextTextarea";
import { apiUrl, imagesUrl } from "../../../../../../config";
import CallAPI from "../../../../../api/callApi";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import SegmentPopup from "../../../../../popup/segmentPopup";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import SegmentRuleContainer from "../../../../../utils/segment/SegmentRuleContainer";
import SegmentRuleRowContainer from "../../../../../utils/segment/SegmentRuleRowContainer";
import items from "./SegmentItems.json";
import "react-json-view-lite/dist/index.css";
import { v4 as uuidv4 } from "uuid";
import ApprovalPopup from "../../../../../popup/approvalPopup";
import InputContainer from "../../../../../utils/inputContainer";
// Recursive function to add `selected` property to each item

const SegmentCreation = () => {
  const [loaderState, setLoaderState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { returnedSegment } = location.state || "";
  const [newItems, setNewItems] = useState();
  const { t } = useTranslation("segmentation");
  const [segmentName, setSegmentName] = useState("");
  const [segmentDescription, setSegmentDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [segmentObject, setSegmentObject] = useState(false);
  const [segmentPopupState, setSegmentPopupState] = useState(false);
  const segmentNameMaxLength = 60;
  const segmentNameMinLength = 2;
  const segmentDescriptionMaxLength = 255;
  const [subRules, setSubRules] = useState([]);
  const [topValue, setTopValue] = useState(null);
  const [selectedLogic, setSelectedLogic] = useState("AND");
  const [returnedSubRules, setReturnedSubRules] = useState([]);
  const handleInputChange = (value, setter) => {
    setter(value);
  };

  const addIdsToSubRules = (subRules) => {
    return subRules.map((subRule) => ({
      ...subRule,
      id: uuidv4(),
    }));
  };

  useEffect(() => {
    if (returnedSegment) {
      console.log("RETURNED", returnedSegment);
      setSegmentName(returnedSegment.name);
      setSegmentDescription(returnedSegment.description);
      setTopValue(returnedSegment.rule.limit);
      const subRulesWithIds = addIdsToSubRules(returnedSegment.rule.subRules);
      setSelectedLogic(returnedSegment.rule.combineOperator);
      subRulesWithIds.forEach((subRule) => {
        const newSubRule = {
          id: uuidv4(),
          expressions: subRule.expressions,
          combineOperator: subRule.combineOperator,
        };
        setReturnedSubRules((prevSubRules) => [...prevSubRules, newSubRule]);
      });
    }
  }, [returnedSegment]);

  const fetchSegmentStoresCities = async () => {
    setLoaderState(true);
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: apiUrl + "/api/v1/brand-admin/brand/segment/stores",
        body: undefined,
        successMessage: undefined,
        errorMessage: t("errorMessage"),
        navigate: navigate,
        t: t,
      });

      const data = response.data.responseData;
      // items dizisinin bir kopyasını oluşturun
      const updatedItems = [...items];
      const storedSubBrands = JSON.parse(localStorage.getItem("subBrands"));

      if (storedSubBrands && Array.isArray(storedSubBrands)) {
        // If it exists, parse and set the state
        console.log(storedSubBrands)
        const subBrandObject = {
          title: "SUB_BRAND",
          valueType: "TEXT",
          field: "SUB_BRAND",
          keyword: "SUB_BRAND",
          multiselect: false,
          requiresDateRange: false,
          details: storedSubBrands.map((subBrand) => ({
            title: subBrand.name,
            valueType: "TEXT",
            multiselect: false,
            keyword: subBrand.name,
            field: "SUB_BRAND",
            requiresDateRange: false,
          })),
        }
        updatedItems.push(subBrandObject);

      }
      if (data.cities && Array.isArray(data.cities) && data.cities.length > 0) {
        const cityObject = {
          title: "CITY",
          valueType: "TEXT",
          field: "CITY",
          keyword: "CITY",
          multiselect: true,
          requiresDateRange: true,
          details: data.cities.map((city) => ({
            title: city,
            valueType: "TEXT",
            multiselect: true,
            keyword: city,
            field: "CITY",
            requiresDateRange: true,
          })),
        };
        updatedItems.push(cityObject);
      }
      if (data.stores && Array.isArray(data.stores) && data.stores.length > 0) {
        const storeObject = {
          title: "STORE",
          valueType: "TEXT",
          keyword: "STORE",
          multiselect: true,
          requiresDateRange: true,
          details: data.stores.map((store) => ({
            title: store,
            valueType: "TEXT",
            field: "STORE",
            keyword: store,
            requiresDateRange: true,
            multiselect: true,
          })),
        };
        updatedItems.push(storeObject);
      }
      if (
        data.categories &&
        Array.isArray(data.categories) &&
        data.categories.length > 0
      ) {
        const categoryObject = {
          title: "PRODUCT_CATEGORY",
          valueType: "TEXT",
          keyword: "PRODUCT_CATEGORY",
          multiselect: true,
          requiresDateRange: true,
          details: data.categories.map((category) => ({
            title: category,
            valueType: "TEXT",
            field: "PRODUCT_CATEGORY",
            keyword: category,
            requiresDateRange: true,
            multiselect: true,
          })),
        };
        updatedItems.push(categoryObject);
      }

      setNewItems(updatedItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoaderState(false);
    }
  };

  useEffect(() => {
    fetchSegmentStoresCities();
  }, []);
  function processExpressions(data) {
    const groupedExpressions = data.expressions.reduce((acc, exp) => {
      if (!acc[exp.field]) {
        acc[exp.field] = [];
      }
      acc[exp.field].push(exp);
      return acc;
    }, {});

    Object.keys(groupedExpressions).forEach((field) => {
      const expressions = groupedExpressions[field];
      let maxGTE = -Infinity;
      let minGTE = Infinity;
      let maxLTE = -Infinity;
      let minLTE = Infinity;
      let hasGTE = false;
      let hasLTE = false;

      expressions.forEach((expression) => {
        const value = parseFloat(expression.value[0]);
        if (expression.operator === "GTE") {
          hasGTE = true;
          if (value > maxGTE) maxGTE = value;
          if (value < minGTE) minGTE = value;
        } else if (expression.operator === "LTE") {
          hasLTE = true;
          if (value > maxLTE) maxLTE = value;
          if (value < minLTE) minLTE = value;
        }
      });

      // Check only if both GTE and LTE values exist
      if (hasGTE && hasLTE) {
        if (data.combineOperator === "AND") {
          if (expressions.some((exp) => exp.operator === "GTE")) {
            console.log("Field:", field, "Max GTE Value:", maxGTE);
          }
          if (expressions.some((exp) => exp.operator === "LTE")) {
            console.log("Field:", field, "Max LTE Value:", maxLTE);
          }

          // Check for logic error
          if (maxGTE > maxLTE) {
            toast.error(`${t("logicErrorMessage", { field: t(field) })}`);
            return false;
          }
        } else if (data.combineOperator === "OR") {
          if (expressions.some((exp) => exp.operator === "GTE")) {
            console.log("Field:", field, "Min GTE Value:", minGTE);
          }
          if (expressions.some((exp) => exp.operator === "LTE")) {
            console.log("Field:", field, "Min LTE Value:", minLTE);
          }

          // Check for logic error
          if (minGTE > minLTE) {
            toast.error(`${t("logicErrorMessage", { field: t(field) })}`);
            return false;
          }
        }
      }
    });
  }

  const handleCreateSegment = async () => {
    if (
      segmentName === undefined ||
      segmentName === undefined ||
      segmentName === ""
    ) {
      toast.error(t("pleaseEnterASegmentName"));
      return;
    }
    if (segmentName < 2) {
      toast.error(t("segmentNameCannotBeSmallerThan2"));
      return;
    }
    const rule = {
      subRules: removeIdsRecursively(subRules),
      combineOperator: selectedLogic, // Use selectedLogic
      limit: topValue || null,
    };

    console.log(rule);
    rule.subRules.forEach((subRule) => {
      processExpressions(subRule);
    });
    setSegmentObject({
      name: segmentName,
      description: segmentDescription || null,
      rule: rule,
    });
    console.log("SEGMENTOBJECT", {
      name: segmentName,
      description: segmentDescription || null,
      rule: rule,
    });
    setSegmentPopupState(true);
  };
  const removeIdsRecursively = (obj) => {
    if (Array.isArray(obj)) {
      // Flatten nested arrays and process each element recursively
      return obj.flat().map(removeIdsRecursively);
    } else if (typeof obj === "object" && obj !== null) {
      const { id, ...rest } = obj; // Remove the `id` property
      return Object.keys(rest).reduce((acc, key) => {
        acc[key] = removeIdsRecursively(rest[key]); // Recursively process each property
        return acc;
      }, {});
    }
    return obj; // Return the value if it's not an array or object
  };

  const publishSegment = async () => {
    setLoaderState(true);
    if (returnedSegment) {
      const response = await CallAPI({
        method: "PUT",
        endPoint:
          apiUrl + `/api/v1/brand-admin/brand/segment/${returnedSegment.id}`,
        body: segmentObject,
        successMessage: t("successMessageSegmentUpdate"),
        errorMessage: t("errorMessageSegmentUpdate"),
        navigate: navigate,
        t: t,
      });
      setLoaderState(false);
      console.log(
        "UPDATE EDERKEN REQUEST-ID",
        response?.data?.metadata?.requestId
      );
      console.log("UPDATE EDERKEN PUT ENDPOINTINDEN DONEN RESPONE", response);
      if (
        response?.status &&
        response.status >= 200 &&
        response.status <= 299
      ) {
        navigate("/brand/srm/segment/list", {
          state: { segment: response.data.responseData },
        });
      } else {
      }
    } else {
      const response = await CallAPI({
        method: "POST",
        endPoint: apiUrl + "/api/v1/brand-admin/brand/segment",
        body: segmentObject,
        successMessage: t("successMessageSegmentCreation"),
        errorMessage: t("errorMessageSegmentCreation"),
        navigate: navigate,
        t: t,
      });

      setLoaderState(false);
      console.log(
        "CREATE EDERKEN REQUEST-ID",
        response?.data?.metadata?.requestId
      );
      console.log("CREATE EDERKEN POST ENDPOINTINDEN DONEN RESPONE", response);
      if (
        response?.status &&
        response?.status >= 200 &&
        response?.status <= 299
      ) {
        setSegmentName("");
        setSegmentDescription("");
        navigate("/brand/srm/segment/list", {
          state: { segment: response.data.responseData },
        });
      } else {
      }
    }
  };

  useEffect(() => {
    // Update combineOperator and subRules when selectedLogic changes
    const updatedRules = subRules.map((rule) => ({
      ...rule,
      combineOperator: selectedLogic,
    }));

    setSubRules(updatedRules);
  }, [selectedLogic]);

  const onClose = () => {
    setSegmentPopupState(false);
  };
  const onAccept = () => {
    setSegmentPopupState(false);
    publishSegment();
  };

  const locationArray = [
    [t("segments"), "/brand/srm/segment"],
    [
      returnedSegment ? t("segmentUpdate") : t("segmentCreate"),
      "/brand/srm/segment/create",
    ],
  ];

  const addNewSubRule = () => {
    const newSubRule = {
      id: uuidv4(),
      expressions: [],
      combineOperator: selectedLogic, // Use selectedLogic
      // other default properties if needed
    };
    setSubRules([...subRules, newSubRule]);
  };

  const deleteSubRule = (id) => {
    setSubRules(subRules.filter((rule) => rule.id !== id));
  };

  return (
    <div className="dashboard-container">
      {segmentPopupState && (
        <ApprovalPopup
          questionText={t("segmentAcceptQuestion")}
          cancelText={t("cancel")}
          approveText={
            returnedSegment ? t("updateSegment") : t("publishSegment")
          }
          onClose={() => {
            onClose();
          }}
          isPositiveApprove={true}
          approvalObject={segmentObject}
          onApproveButtonClick={onAccept}
          onCancelButtonClick={onClose}
        />
      )}
      <NavigationBar locationArray={locationArray} />

      {loaderState && <Loader />}
      <div className="dashboard-row">
        <div className="challenge-creation-content-container">
          {/* SEGMENT ADI*/}
          <SingleTextInput
            title={t("segmentName")}
            isMandatory={true}
            titleDescription={t("enterSegmentName")}
            inputPlaceholder={t("segmentName")}
            value={segmentName}
            onChange={(e) => handleInputChange(e.target.value, setSegmentName)}
            onBlur={(e) => handleInputChange(e.target.value, setSegmentName)}
            maxLength={segmentNameMaxLength}
            minLength={segmentNameMinLength}
            infoText={[`${t("max")} ${segmentNameMaxLength} ${t("character")}`]} // Wrap the string in an array
          />
          {/* SEGMENT AÇIKLAMASI */}
          <SingleTextTextarea
            title={t("segmentDescription")}
            titleDescription={t("enterSegmentDescription")}
            inputPlaceholder={t("segmentDescription")}
            value={segmentDescription}
            onChange={(e) =>
              handleInputChange(e.target.value, setSegmentDescription)
            }
            onBlur={(e) =>
              handleInputChange(e.target.value, setSegmentDescription)
            }
            maxLength={segmentDescriptionMaxLength}
            infoText={[
              `${t("max")} ${segmentDescriptionMaxLength} ${t("character")}`,
            ]} // Wrap the string in an array
          />

          <InputContainer
            title={t("Rules")}
            isMandatory
            titleDescription={t("specifyTheRules")}
          >
            <SegmentRuleContainer setTopValue={setTopValue} topValue={topValue}>
              {newItems && (
                <SegmentRuleRowContainer
                  items={newItems}
                  setSubRules={setSubRules}
                  subRules={subRules}
                  returnedSegment={returnedSegment}
                  returnedSubRules={returnedSubRules}
                  addNewSubRule={addNewSubRule}
                  deleteSubRule={deleteSubRule}
                  selectedLogic={selectedLogic}
                  setSelectedLogic={setSelectedLogic}
                />
              )}
            </SegmentRuleContainer>
          </InputContainer>

          <div className="interaction-type-preview-challenge-release-button-wrapper">
            <button
              className="interaction-type-preview-challenge-release-button"
              onClick={() => {
                handleCreateSegment();
              }}
            >
              {returnedSegment ? t("updateSegment") : t("publishSegment")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentCreation;
