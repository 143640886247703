import React, { useState } from "react";
import "../../../../../assets/css/ChallengeCreation.css";
import { useTranslation } from "react-i18next";
import SurveyCreation from "../survey_creation/SurveyCreation";
import InteractionCreation from "../interaction_creation/InteractionCreation";
import ContentCreation from "../content_creation/ContentCreation";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";

const ChallengeCreation = () => {
  const { t } = useTranslation("challengeCreation");
  const [activeTab, setActiveTab] = useState("contentCreation");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const locationTitle = () => {
    if (activeTab === "contentCreation") {
      return t("createContent");
    } else if (activeTab === "interaction") {
      return t("interaction");
    } else if (activeTab === "survey") {
      return t("createSurvey");
    }
  };

  const locationArray = [
    [t("challenges"), "/brand/challenges"],
    [t("createChallenge"), "/brand/challenges/challenge-creation"],
    [locationTitle(), "/brand/challenges/challenge-creation"],
  ];
  return (
    <div className="dashboard-container">
      <NavigationBar locationArray={locationArray} />
      <div className="challenge-creation-tabs">
        <div
          className={`challenge-creation-tab ${
            activeTab === "contentCreation" ? "activeChallengeTab" : ""
          }`}
          onClick={() => handleTabClick("contentCreation")}
        >
          {t("contentCreation")}
        </div>
        <div
          className={`challenge-creation-tab ${
            activeTab === "interaction" ? "activeChallengeTab" : ""
          }`}
          onClick={() => handleTabClick("interaction")}
        >
          {t("interaction")}
        </div>
        <div
          className={`challenge-creation-tab ${
            activeTab === "survey" ? "activeChallengeTab" : ""
          }`}
          onClick={() => handleTabClick("survey")}
        >
          {t("survey")}
        </div>
      </div>

      {activeTab === "survey" && <SurveyCreation />}
      {activeTab === "interaction" && <InteractionCreation />}
      {activeTab === "contentCreation" && <ContentCreation />}
    </div>
  );
};

export default ChallengeCreation;
