import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import placeholderImg from "../../../../../../assets/media/placeholder.png";
import locationIcon from "../../../../../../assets/media/locationIcon.svg";
import mailIcon from "../../../../../../assets/media/mail.svg";
import { apiUrl, imagesUrl } from "../../../../../../config";
import CustomBackgroundButton from "../../../../../button/customBackgroundButton";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import CustomTabButton from "../../../../../button/customTabButton";
import SotierAnalytics from "./analytics/sotierAnalytics";
import DynamicPopupWithChildren from "../../../../../popup/dynamicPopupWithChildren";
import SingleTextInput from "../../../../../utils/singleTextInput";
import SingleTextTextarea from "../../../../../utils/singleTextTextarea";
import SingleNumberInput from "../../../../../utils/singleNumberInput";
import { toast } from "react-toastify";
import CallAPI from "../../../../../api/callApi";
import DynamicTableWithInnerElements from "../../../../../tables/DynamicTableWithInnerElements";
import DynamicTable from "../../../../../tables/DynamicTable";

const SotierProfile = () => {
  const { t } = useTranslation("sotierList");
  const { id } = useParams(); // URL'den id'yi al
  const navigate = useNavigate();
  const location = useLocation();
  const [sotier, setSotier] = useState(null);
  const [activeTab, setActiveTab] = useState("Analytics"); // State for active tab
  const [giftCoinPopup, setGiftCoinPopup] = useState(false);
  const [giftCoinTitle, setGiftCoinTitle] = useState();
  const [giftCoinDescription, setGiftCoinDescription] = useState();
  const [giftCoinValue, setGiftCoinValue] = useState();
  const [segments, setSegments] = useState([]);
  useEffect(() => {
    getSotier(id); // URL parametresinden id'yi kullan
  }, [id]);

  useEffect(() => {
    console.log(sotier);
  }, [sotier]);
  const getSotier = async (sotierId) => {
    const response = await CallAPI({
      method: "get",
      endPoint: apiUrl + "/api/v1/brand-admin/sotier/" + sotierId + "/profile",
    });
    setSotier(response.data.responseData);
    const formattedSegments = response.data.responseData.segments.map(
      (segment) => ({
        ...segment,
        created: new Date(segment.created).toLocaleDateString(
          localStorage.getItem("language"),
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }
        ),
        updated: new Date(segment.updated).toLocaleDateString(
          localStorage.getItem("language"),
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }
        ),
      })
    );
    setSegments(formattedSegments);
  };

  // Destructure sotier to use individual properties
  const segmentColumns = [
    { title: t("id"), field: "id" },
    { title: t("name"), field: "name" },
    { title: t("created"), field: "created" },
    { title: t("updated"), field: "updated" },
  ];
  const locationArray = [
    [t("sotierList"), "/brand/srm/sotier/list"],
    [
      sotier?.userName ? sotier?.userName : t("profile"),
      "/brand/srm/sotier/profile",
    ],
  ];

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    console.log(`Selected Tab: ${tab}`);
  };

  const tabData = [
    { key: "Analytics", label: "Analitik" },
    {
      key: "Segments",
      label: t("segments"),
      path: "/brand/sotier/profile/segments",
    },
  ];

  const cardsData = [
    {
      topTitle: "Total",
      title: "Shopping Amount",
      valueColor: "#2F3397",
      short: "TSA",
      value: "13.000 ₺",
      tableHeaders: ["Order ID", "Soty Coin", "Date"],
      tableData: [
        ["1", "300", "25.09.2023"],
        ["2", "350", "26.09.2023"],
      ],
    },
    {
      topTitle: "Total",
      title: "Earned Soty Coin",
      valueColor: "#319151",
      short: "TESC",
      value: "5000",
      tableHeaders: ["Order ID", "Soty Coin", "Date"],
      tableData: [
        ["3", "550", "27.09.2023"],
        ["4", "450", "28.09.2023"],
      ],
    },
    // Add additional card objects with their own `tableHeaders` and `tableData`
  ];

  const handleGiftCoin = () => {
    if (giftCoinTitle === undefined || giftCoinTitle === "") {
      toast.error(t("giftCoinTitleCannotBeEmpty"));
      return;
    } else if (
      giftCoinDescription === undefined ||
      giftCoinDescription === ""
    ) {
      toast.error(t("giftCoinDescriptionCannotBeEmpty"));
      return;
    } else if (giftCoinValue === undefined || giftCoinValue === "") {
      toast.error(t("giftCoinValueCannotBeEmpty"));
      return;
    } else if (giftCoinValue <= 0) {
      toast.error(t("giftCoinValueMustBeHigherThanZero"));
      return;
    }

    alert("COIN GIFTED TO " + sotier?.userName);

    resetGiftCoinPopup();
  };

  const resetGiftCoinPopup = () => {
    setGiftCoinTitle();
    setGiftCoinDescription();
    setGiftCoinValue();
    setGiftCoinPopup(false);
  };

  return (
    <div className="dashboard-container profile-dashboard-background">
      <NavigationBar locationArray={locationArray} />
      {giftCoinPopup && (
        <DynamicPopupWithChildren
          onCancel={() => resetGiftCoinPopup()}
          title={t("giftSotyCoin")}
          isCondition
          buttons
          acceptText={t("sendSotyCoin")}
          onAccept={() => handleGiftCoin()}
          cancelText={t("cancel")}
          maxWidth="100vw"
          Width="50vw"
        >
          <div className="flex flex-column gap-50">
            <SingleTextInput
              title={t("giftCoinTitle")}
              isMandatory={true}
              titleDescription={t("giftCoinTitleDescription")}
              inputPlaceholder={t("giftCoinTitlePlaceholder")}
              value={giftCoinTitle}
              onChange={(e) => setGiftCoinTitle(e.target.value)}
              onBlur={(e) => setGiftCoinTitle(e.target.value)}
            />
            <SingleTextTextarea
              title={t("giftCoinDesc")}
              isMandatory={true}
              titleDescription={t("giftCoinDescDescription")}
              inputPlaceholder={t("giftCoinDescPlaceholder")}
              value={giftCoinDescription}
              onChange={(e) => setGiftCoinDescription(e.target.value)}
              onBlur={(e) => setGiftCoinDescription(e.target.value)}
            />
            <SingleNumberInput
              title={t("giftCoinValue")}
              isMandatory={true}
              inputPlaceholder={300}
              titleDescription={t("giftCoinValueDesc")}
              value={giftCoinValue}
              onChange={(e) => setGiftCoinValue(e.target.value)}
              onBlur={(e) => setGiftCoinValue(e.target.value)}
            />
          </div>
        </DynamicPopupWithChildren>
      )}

      <div className="profile-dashboard">
        <div className="sotier-profile-row-container profile-card">
          <div className="sotier-profile-row-top">
            <div className="sotier-profile-row-left">
              <div className="sotier-profile-credentials-container">
                <img
                  className="sotier-profile-picture"
                  src={
                    sotier?.logo
                      ? imagesUrl + "/" + sotier?.logo
                      : placeholderImg
                  }
                  alt={t("profilePictureAlt")} // Localization for alt text
                />
                <div className="sotier-profile-credentials">
                  <div className="sotier-profile-name-container">
                    <div>{sotier?.userName}</div>
                    <div>{sotier?.firstName}</div>
                    <div>{sotier?.lastName}</div>
                  </div>
                  <div className="sotier-profile-contact-container">
                    <div>
                      {t("sotierId")}: {sotier?.sotierId}
                    </div>
                    <div>
                      <img src={locationIcon} alt={t("locationAlt")} />
                      {sotier?.city || t("notAvailable")}
                    </div>
                    <div>
                      <img src={mailIcon} alt={t("mailAlt")} />
                      {sotier?.email || ""}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("birthDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.birthDate
                        ? new Date(sotier.birthDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      {t("membershipDateTitle")}
                    </div>
                    <div className="sotier-profile-credential">
                      {sotier?.membershipDate
                        ? new Date(sotier.membershipDate).toLocaleDateString(
                            localStorage.getItem("language"),
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            }
                          )
                        : t("notAvailable")}
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <CustomBackgroundButton
                      title={t("giftSotyCoin")}
                      onClick={() => setGiftCoinPopup(true)}
                      backgroundColor={"#45C7D1"}
                    />
                    <CustomBackgroundButton
                      title={t("penaltyManagement")}
                      onClick={() => {
                        navigate(`/brand/reports/detail/${sotier.sotierId}`);
                      }}
                      backgroundColor={"#DC6767"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="vertical-line sotier-profile-vertical-line-padding" />
            <div className="sotier-profile-row-right">
              <div className="sotier-profile-row-right-row display-none-important">
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell">
                  <img src={instagramIcon} alt={t("instagramAlt")} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={youtubeIcon} alt={t("youtubeAlt")} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={facebookIcon} alt={t("facebookAlt")} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={twitterIcon} alt={t("twitterAlt")} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={tiktokIcon} alt={t("tiktokAlt")} />
                </div>
              </div>
              <div className="sotier-profile-row-right-row display-none-important">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("engagementRate")}
                </div>
                <div className="sotier-profile-row-right-row-cell">%5</div>
                <div className="sotier-profile-row-right-row-cell">%2</div>
                <div className="sotier-profile-row-right-row-cell">%1</div>
                <div className="sotier-profile-row-right-row-cell">%3</div>
                <div className="sotier-profile-row-right-row-cell">%10</div>
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("loyaltyLevel")}
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  {sotier?.loyaltyLevel}
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
              <div className="sotier-profile-row-right-row display-none-important">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("segments")}
                </div>
                {sotier?.segments.slice(0, 4).map((segment, index) => (
                  <div
                    className="sotier-profile-row-right-row-cell"
                    key={segment.id}
                  >
                    <div className="sotier-profile-row-right-segment-wrapper">
                      ID : {segment.id}
                    </div>
                  </div>
                ))}

                {/* Boş div'leri ekleyelim */}
                {Array.from({
                  length: 5 - Math.min(sotier?.segments.length, 4),
                }).map((_, index) => (
                  <div
                    className="sotier-profile-row-right-row-cell"
                    key={`empty-${index}`}
                  />
                ))}
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  {t("subscriptionBrand")}
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-subscribe-wrapper">
                    {sotier?.subscriptionCount || t("notAvailable")}
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
            </div>
          </div>
          <div className="sotier-profile-row-bottom">
            {tabData.map(({ key, label }) => (
              <CustomTabButton
                key={key}
                borderBottomColor={
                  activeTab === key ? "#04C8C8" : "transparent"
                }
                onClick={() => handleTabChange(key)}
                title={label}
              />
            ))}
          </div>
        </div>

        {(activeTab == "Analytics" || activeTab == "") && (
          <SotierAnalytics cardsData={cardsData} />
        )}
        {activeTab == "Segments" && (
          <DynamicTable columns={segmentColumns} data={segments} />
        )}
      </div>
    </div>
  );
};

export default SotierProfile;
